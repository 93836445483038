<script setup lang="ts">
/**
 * @author Beka Chkhaidze
 *
 * @summary customized calendar based on: @vue3datepicker
 * @note Original source code is located in UIComponents/ folder.
 */

import { computed, onBeforeMount, ref, nextTick } from "vue";
import { useCalendar } from "./ts/calendar";
import { useMainStore } from "@/store/main/index";
import { useBookingStore } from "@/store/booking";
import type { SelectInterface } from "@/types/FormInterface";

import VueDatePicker from "@/components/regularComponents/UIComponents/Calendar/VueDatePicker.vue";
import SelectInput from "../SelectInput.vue";

const props = withDefaults(
  defineProps<{
    modelValue: number | Date;
    timezone?: SelectInterface;
    minDate?: Date;
    direction?: "bottom" | "top";
  }>(),
  {
    modelValue: new Date().getTime(),
    // @ts-ignore
    minDate: new Date(),
    // @ts-ignore
    timezone: undefined,
    direction: "bottom",
  }
);

const emit = defineEmits(["update:modelValue", "update:timezone", "height-changed"]);

const { disabledDates, getAvailableDays, handleMonthYear } = useCalendar();

await getAvailableDays();

const mainStore = useMainStore();
const bookingStore = useBookingStore();

const datePicker = ref<HTMLDivElement>();

const defaultTimeZone = computed(() => {
  const defaultTimeZoneVal = bookingStore?.timezone;
  if(!defaultTimeZoneVal) return;
  if(!props?.timezone) {
    props.timezone = defaultTimeZoneVal
  } 
  return defaultTimeZoneVal;
})
const inputModel = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const timezoneModel = computed({
  get() {
    return props.timezone || defaultTimeZone.value;
  },
  set(newValue) {
    emit("update:timezone", newValue);
  },
});

const emitHeightChange = () => {
  emit("height-changed", datePicker.value?.offsetHeight);
};

onBeforeMount(async () => {
  emitHeightChange();
});
</script>

<template>
  <section class="custom-calendar" ref="datePicker">
    <VueDatePicker
      v-model="inputModel"
      v-bind="{
        minDate,
        inline: true,
        locale: mainStore.lang,
        monthNameFormat: 'long',
        enableTimePicker: false,
        autoApply: true,
        monthChangeOnScroll: false,
        preventMinMaxNavigation: true,
        disabledDates,
      }"
      @update-month-year="
        async (e) => {
          await handleMonthYear(e);
          await nextTick();
          emitHeightChange();
        }
      "
    />
    <SelectInput
      label="Time Zone"
      v-model="timezoneModel"
      :items="bookingStore?.timezones"
      :direction="direction"
    />
  </section>
</template>

<style lang="scss" src="./styles/calendar.scss" />
